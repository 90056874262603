import legendsImg from "../assets/Token Legends .png";
import elem from "../assets/elem2.png";
import Chart from "./Chart";

function Tokenomics() {
  return (
    <section className="Tokenomics">
      <img src={elem} alt="" className="elem" />
      <h2>Tokenomics</h2>
      <div className="flex">
        <Chart />
        <img src={legendsImg} alt="" className="legends" />
      </div>

      <p> subject .................</p>
    </section>
  );
}

export default Tokenomics;
