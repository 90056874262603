import { useState, useRef, useEffect } from "react";
import chartImag from "../assets/token pie chart.png";

function Chart() {
  const [currentTitle, setCurrentTitle] = useState(""); // State to hold the hovered title
  const [hovered, setHovered] = useState(false); // State to track hover state
  const imgRef = useRef(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (imgRef.current) {
        setImgWidth(imgRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial size setup

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = (title) => {
    setCurrentTitle(title);
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setCurrentTitle("");
    setHovered(false);
  };

  // Function to scale coordinates dynamically based on image width
  const scaleCoords = (coords) => {
    const originalWidth = 2901; // Set this to the original image width
    const scaleFactor = imgWidth / originalWidth;
    return coords
      .split(",")
      .map((coord) => Math.round(parseInt(coord) * scaleFactor))
      .join(",");
  };

  return (
    <div className="chart-container" style={{ position: "relative" }}>
      <img
        ref={imgRef}
        src={chartImag}
        useMap="#image-map"
        className="pieChart"
        alt="Token Pie Chart"
      />

      {/* Custom Tooltip Centered on the Pie Chart */}
      {hovered && <div className="custom-tooltip">{currentTitle}</div>}

      {/* image map ================ */}
      <map name="image-map">
        <area
          alt="Staking"
          data-title="Staking"
          href="#"
          coords={scaleCoords(
            "1198,1980,1293,2021,1485,2037,1657,2008,1795,1960,1897,1891,2261,2326,2092,2446,1856,2557,1623,2610,1398,2633,1191,2613,1040,2549"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Staking")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Treasury"
          data-title="Treasury"
          href="#"
          coords={scaleCoords(
            "1198,1985,1078,1919,973,1814,917,1742,446,2078,558,2224,694,2339,784,2416,896,2469,1034,2539"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Treasury")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Liquidity & Market Making"
          data-title="Liquidity & Market Making"
          href="#"
          coords={scaleCoords(
            "233,1360,817,1350,837,1540,899,1711,914,1742,443,2093,351,1947,274,1716,243,1537"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Liquidity & Market Making")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Team & Advisors"
          data-title="Team & Advisors"
          href="#"
          coords={scaleCoords(
            "241,1353,822,1353,842,1186,901,1068,996,953,1083,858,755,385,510,595,323,928,248,1161"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Team & Advisors")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Marketing"
          data-title="Marketing"
          href="#"
          coords={scaleCoords(
            "755,377,919,290,1073,216,1239,177,1434,164,1439,741,1083,861"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Marketing")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Seed Investors"
          data-title="Seed Investors"
          href="#"
          coords={scaleCoords(
            "1436,154,1590,162,1698,167,1810,216,1636,761,1444,736"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Seed Investors")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Private Investors"
          data-title="Private Investors"
          href="#"
          coords={scaleCoords(
            "1810,213,1954,259,2061,310,2246,431,1844,879,1634,761"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Private Investors")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="KOL Investors"
          data-title="KOL Investors"
          href="#"
          coords={scaleCoords("1861,864,1925,933,2361,543,2235,428")}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("KOL Investors")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Public"
          data-title="Public"
          href="#"
          coords={scaleCoords("1933,935,2063,1130,2606,928,2514,743,2360,541")}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Public")}
          onMouseLeave={handleMouseLeave}
        />
        <area
          alt="Ecosystem"
          data-title="Ecosystem"
          href="#"
          coords={scaleCoords(
            "1895,1885,2251,2326,2391,2201,2530,1993,2640,1783,2673,1624,2701,1422,2701,1281,2663,1086,2607,930,2061,1130,2115,1378,2117,1517,2053,1663,1984,1780"
          )}
          shape="poly"
          onMouseEnter={() => handleMouseEnter("Ecosystem")}
          onMouseLeave={handleMouseLeave}
        />
      </map>
    </div>
  );
}

export default Chart;
