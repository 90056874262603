import elem from "../assets/elem11.png";

function EarnXP() {
  const xpObj = [
    {
      xp: "50 XP",
      title: "Sign up & Verified Email",
    },
    {
      xp: "10 XP",
      title: "Join Free Game Room",
    },
    {
      xp: "20 x $ value XP",
      title: "Join Paid Game Room",
    },
    {
      xp: "50 XP",
      title: "Successful Referrals",
    },
    {
      xp: "100 XP",
      title: "Join 10 Game Rooms",
    },
    {
      xp: "100 XP",
      title: "Win a Game Room",
    },
  ];
  return (
    <section className="EarnXpSection">
      <img src={elem} alt="" className="elem" />
      <h2>How to Earn XPs</h2>
      <div className="row">
        {xpObj.map((item, index) => (
          <div className={`xpBox box${index}`} key={index}>
            <h1>{item.xp}</h1>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default EarnXP;
