import Logo from "../assets/newLogo 1.png";
import ParticleBG from "./ParticleBG";
import { useEffect, useRef } from "react";

function HeroSection() {
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (heroSectionRef.current) {
        // Normalize mouse position (percentage relative to screen size)
        const pageX = (e.pageX / window.innerWidth) * 100 - 50; // -50 to +50 range
        const pageY =
          ((e.pageY < 800 ? e.pageY : 800) / window.innerWidth) * 100 - 50; // -50 to +50 range;

        // Set the custom properties (CSS variables) for perspective
        heroSectionRef.current.style.setProperty("--page-x", pageX);
        heroSectionRef.current.style.setProperty("--page-y", pageY);
      }
    };

    // Add event listener for mouse movement
    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="HeroSection">
      <div className="hero-bg"></div>
      <div className="hero-container ">
        <div className="mouseTrack" ref={heroSectionRef}>
          <img className="logo" src={Logo} alt="PanenkaFC" />
          <h1>Welcome</h1>
          <p>
            We&apos;re passionate fans building a fantasy gaming experience that
            reflects your love for football
          </p>
          <button className="btn" style={{marginInline:"auto", marginTop:"20px"}}>Know more</button>
        </div>
      </div>
      <div className="scroll-container">
        <p>Scroll</p>
        <i className="bi bi-chevron-double-down"></i>
      </div>
      <div className="partibleBG">
        <ParticleBG />
      </div>
      <div className="gradientLayer"></div>
    </div>
  );
}

export default HeroSection;
